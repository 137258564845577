import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';

function Result() {
  const { quizUrl } = useParams();
  const [resultData, setResultData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.answers) {
      axios
        .post(`${process.env.REACT_APP_API_BACKEND_BASE_URL}/quiz/submit`, { quiz_url: quizUrl, answers: location.state.answers })
        .then((response) => setResultData(response.data))
        .catch((error) => console.error(error));
    } else {

      
      // Handle the case when location.state is null or doesn't contain answers
      setResultData(null);
    }
  }, [quizUrl, location.state]);

  if (!resultData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Quiz Result</h1>
      <h2>{resultData.nama_tes}</h2>
      {Object.entries(resultData.hasil).map(([categoryId, categoryData]) => (
        <div key={categoryId}>
          <h3>{categoryData.nama_kategori}</h3>
          <p>Score: {categoryData.nilai}</p>
          <p>Conversion: {categoryData.konversi}</p>
        </div>
      ))}
    </div>
  );
}

export default Result;
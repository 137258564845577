import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function StartQuiz() {
  const { quizUrl } = useParams();
  const [quizData, setQuizData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BACKEND_BASE_URL}/quiz/${quizUrl}`)
      .then(response => setQuizData(response.data))
      .catch(error => console.error(error));
  }, [quizUrl]);

  const startQuiz = () => navigate(`/quiz/${quizUrl}`);

  return (
    <div>
      <h1>Start Quiz</h1>
      {quizData && (
        <div>
          <h2>{quizData.quiz.nama_tes}</h2>
          <button onClick={startQuiz}>Start Quiz</button>
        </div>
      )}
    </div>
  );
}

export default StartQuiz;
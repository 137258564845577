import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function Quiz() {
  const { quizUrl } = useParams();
  const [quizData, setQuizData] = useState(null);
  const [otp, setOtp] = useState('');
  const [isOtpValid, setOtpValid] = useState(false);
  const [otpError, setOtpError] = useState(null);
  const [answers, setAnswers] = useState({});
  const navigate = useNavigate();

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const submitOtp = () => {
    axios.get(`${process.env.REACT_APP_API_BACKEND_BASE_URL}/quiz/${quizUrl}?otp=${otp}`)
      .then(response => {
        setQuizData(response.data);
        setOtpValid(true);
        const initialAnswers = Object.keys(response.data.quiz.soal).reduce((acc, questionId) => {
          const firstAnswerId = Object.keys(response.data.quiz.soal[questionId].jawaban)[0];
          return { ...acc, [questionId]: parseInt(firstAnswerId) };
        }, {});
        setAnswers(initialAnswers);
      })
      .catch(error => {
        console.error(error);
        setOtpError('Invalid OTP, please try again.');
      });
  };

  const handleAnswerChange = (questionId, answerId) => {
    setAnswers({ ...answers, [questionId]: answerId });
  };

  const submitQuiz = () => { navigate(`/result/${quizUrl}`, { state: { answers } }) };

  return (
    <div>
      <h1>Quiz</h1>
      {!isOtpValid ? (
        <div>
          <input type="text" value={otp} onChange={handleOtpChange} placeholder="Enter OTP" />
          <button onClick={submitOtp}>Submit OTP</button>
          {otpError && <p>{otpError}</p>}
        </div>
      ) : (
        quizData && (
          <div>
            <h2>{quizData.quiz.nama_tes}</h2>
            {Object.entries(quizData.quiz.soal).map(([questionId, questionData]) => (
              <div key={questionId}>
                <h3>{questionData.pertanyaan}</h3>
                {Object.entries(questionData.jawaban).map(([answerId, answerData]) => (
                  <label key={answerId}>
                    <input
                      type="radio"
                      value={answerId}
                      checked={answers[questionId] === parseInt(answerId)}
                      onChange={() => handleAnswerChange(questionId, parseInt(answerId))}
                    />
                    {answerData.pilihan}
                  </label>
                ))}
              </div>
            ))}
            <button onClick={submitQuiz}>Submit Quiz</button>
          </div>
        )
      )}
    </div>
  );
}

export default Quiz;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StartQuiz from './StartQuiz';
import Quiz from './Quiz';
import Result from './Result';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/start/:quizUrl" element={<StartQuiz />} />
        <Route path="/quiz/:quizUrl" element={<Quiz />} />
        <Route path="/result/:quizUrl" element={<Result />} />
        <Route path="/" element={<StartQuiz />} />
      </Routes>
    </Router>
  );
}

export default App;
